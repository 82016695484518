import MasterLayout from "../layouts/master";
import * as React from "react"
import { Input, Heading, Button, Text } from '@chakra-ui/react'

const pageStyles = {
    color: "#232129",
    padding: "96px",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
    display: "flex",
    flexDirection: "column",
    gap: "10px"
}

const RequestDataDeletion = () => {
    return (
        <MasterLayout>
            <main style={pageStyles}>
                <Heading>Request for data deletion.</Heading>
                <Text fontSize='sm' color={"gray"}>Once your data is deleted, it is not possible to recover it or restore any of the associated information. If you change your mind or wish to use our services in the future, you will need to start afresh and provide the necessary data.</Text>
                <Text fontSize='sm' color={"gray"}>Deleting your data may have implications for any connected accounts or integrations you have used with our platform. Ensure that you understand any potential consequences or disruptions before initiating the data deletion process.</Text>
                <Text fontSize='sm' color={"gray"}>Remoing your data will delete any personalized settings, preferences, or customizations you have made within your account. You will need to reconfigure these settings if you decide to use our services again in the future.</Text>
                <Input type="number" placeholder="Enter your phone no." focusBorderColor='green.400' />
                <Button
                    style={{ width: "fit-content" }}
                    fontSize={"0.86rem"}
                    fontWeight={"bold"}
                    color={"white"}
                    _hover={{
                        textDecoration: "none",
                        bg: "#71d387",
                    }}
                    bgColor={"#52bb6a"}
                >
                    Delete
                </Button>
            </main>
        </MasterLayout>
    )
}

export default RequestDataDeletion